import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const CustomersPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.CUSTOMERS.path,
			element: lazy(() => import('./Customers')),
		},
	],
};
