import {
	Button,
	Flex,
	ControlledCheckbox,
	ControlledInput,
	Label,
	LayoutShimmer,
} from '@zeal/web-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TLoginForm } from '../types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	onLoginSuccess,
	useLoggedIn,
	useLogin,
	useLogout,
} from '@app/ACL/useLogin';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFetchTranslation } from '@app/hooks';
import { ROUTES } from '@app/AppRoutes/routes';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';
import { useLoginWithAdminToken } from '@app/ACL/useAdminLogin';
import openToaster from '@app/Toastr/openToastr';

export default function Login() {
	const navigate = useNavigate();
	const [{ login_token: adminToken }] = useTypedSearchParams(ROUTES.LOGIN);

	const { isLoggedIn } = useLoggedIn();

	const [errorToastId, setErrorToastId] = useState<string | null>(null);

	const {
		isTranslationsLoading: isTranslationsLoadingLogin,
	} = useFetchTranslation('login');
	const { isTranslationsLoading } = useFetchTranslation('magicLink');

	const { t } = useTranslation('magicLink');

	const formSchema = yup.object({
		email: yup
			.string()
			.email()
			.required(t('email_error')),
		password: yup
			.string()
			.required(t('password_error'))
			.min(8),
	});

	const formMethods = useForm<TLoginForm>({
		defaultValues: {
			email: '',
			password: '',
			keepSignIn: false,
		},
		resolver: yupResolver(formSchema),
	});
	const { handleSubmit } = formMethods;

	const { mutateAsync: doLogoutAsync } = useLogout();
	const { doLogin, isLoading } = useLogin({
		onSuccess: () => {
			toast.dismiss();
			setTimeout(() => {
				navigate(ROUTES.ROOT.path);
			}, 10);
		},
		onError: ({ toastId }: { toastId: string }) => {
			setErrorToastId(toastId);
		},
	});

	const handleLogin = useCallback(
		(data: TLoginForm) => {
			doLogin(data);

			if (errorToastId) {
				toast.dismiss(errorToastId);
			}
		},
		[doLogin, t, errorToastId]
	);

	const { doLogin: doLoginWithAdminToken } = useLoginWithAdminToken({
		onSuccess: () => {
			openToaster({
				message: t('login:admin_login_success_title'),
				variant: 'success',
			});
			navigate(ROUTES.ROOT.path);
		},
		onError: (err: any) => {
			openToaster({
				message: err?.error?.message || t('login:admin_login_failed_title'),
				variant: 'error',
			});
		},
	});

	const openForgotPassword = useCallback(() => {
		navigate(ROUTES.FORGOT_PASSWORD.path);
	}, [navigate]);

	useEffect(() => {
		if (isLoggedIn && adminToken) {
			doLogoutAsync({})
				.then(() => {
					doLoginWithAdminToken({ token: adminToken });
				})
				.catch(() => {
					openToaster({
						message: t('admin_logout_failed'),
						variant: 'error',
					});
				});
		} else if (adminToken) {
			doLoginWithAdminToken({ token: adminToken });
		} else if (isLoggedIn) {
			navigate(ROUTES.ROOT.path);
		}
	}, []);

	return (
		<LayoutShimmer
			isLoading={
				isLoading || isTranslationsLoading || isTranslationsLoadingLogin
			}
		>
			<FormProvider {...formMethods}>
				<Flex isColumn gap="6" align="center" className="h-full">
					<Flex
						gap="6"
						isColumn
						justify="center"
						className="h-full py-12 px-7"
						style={{
							width: '500px',
						}}
					>
						<Flex gap="2" isColumn>
							<Label.Big600 textAlign="center">{t('login_title')}</Label.Big600>
							<Label.Thin300 textAlign="center" variant="subdued">
								{t('login_description')}
							</Label.Thin300>
						</Flex>
						<Flex gap="16" isColumn>
							<Flex gap="6" isColumn>
								<Flex gap="6" isColumn>
									<ControlledInput<TLoginForm>
										label={t('email')}
										name="email"
										type="email"
										className="w-full"
										startIconProps={{
											iconName: 'envelope',
										}}
										placeholder={t('email_placeholder')}
									/>
									<ControlledInput<TLoginForm>
										label={t('password')}
										name="password"
										type="password"
										className="w-full"
										startIconProps={{
											iconName: 'lockKeyhole',
										}}
										placeholder={t('password_placeholder')}
										autoComplete="new-password"
									/>
								</Flex>
								<Flex align="center" justify="between">
									<Flex align="center" gap="2">
										<ControlledCheckbox<TLoginForm>
											name="keepSignIn"
											className="w-6 h-6"
										/>
										<Label.Thin300>{t('remember_me')}</Label.Thin300>
									</Flex>
									<Flex align="center" gap="2">
										<Button
											variant="transparent"
											className="!m-0 !p-0"
											onClick={openForgotPassword}
										>
											<Label.Mid300 variant="secondary">
												{t('forgot_password')}
											</Label.Mid300>
										</Button>
									</Flex>
								</Flex>
								<Button
									variant="primary"
									size="lg"
									className="w-full"
									isLoading={isLoading}
									onClick={handleSubmit(handleLogin)}
								>
									{t('login')}
								</Button>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</FormProvider>
		</LayoutShimmer>
	);
}
