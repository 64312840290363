import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import { string } from 'yup';

export const CardMachinesRoutes = {
	CARD_MACHINES: route(
		'card-machines',
		{},
		{
			CARD_MACHINES: route('card-machines', {}),
			CUSTOMISE: route('customise', {
				searchParams: {
					onboarding: yup(string().required()),
				},
			}),
		}
	),
};
