import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const upgradePlanPathOptions: TPathOptions = {
	apiKey: 'settings',
	path: ROUTES.UPGRADE_PLAN.path,
	label: 'settings',
	iconName: 'cog',
	menuOrder: false,
	ownApp: false,
	excludedFor: [],
};

export default upgradePlanPathOptions;
