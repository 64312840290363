import React, { useEffect } from 'react';

import { Flex, Grid } from '@zeal/web-ui';

import NavMenu from './components/NavMenu';
import { useFetchTranslation } from '@app/hooks';
import CarouselCard from './components/CarouselCard';
import { useLoggedIn } from '@app/ACL/useLogin';
import { ROUTES } from '@app/AppRoutes/routes';
import { useNavigate } from 'react-router-dom';

export default function MagicLinkLayout({
	children,
}: {
	children: React.ReactNode;
}) {
	useFetchTranslation('magicLink');

	return (
		<Grid cols={2} className="w-screen h-screen">
			<CarouselCard />
			<Flex isColumn>
				<NavMenu />
				{children}
			</Flex>
		</Grid>
	);
}
