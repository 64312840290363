import { create } from 'zustand';
import { ModalProps } from '@zeal/web-ui';

type OpenModalParams = Pick<
	ModalProps,
	| 'onConfirm'
	| 'isDelete'
	| 'confirmTitle'
	| 'icon'
	| 'deleteMsg'
	| 'deleteMsgDescription'
> &
	Partial<
		Pick<
			ModalProps,
			'children' | 'title' | 'onCancel' | 'isLoading' | 'isError'
		>
	>;

type ModalStore = ModalProps & {
	openModal: (params: OpenModalParams) => void;
};

const initialValues: Pick<
	ModalStore,
	| 'isOpen'
	| 'title'
	| 'onConfirm'
	| 'isDelete'
	| 'children'
	| 'isLoading'
	| 'isError'
	| 'confirmTitle'
	| 'icon'
	| 'deleteMsg'
	| 'deleteMsgDescription'
> = {
	isOpen: false,
	title: '',
	isDelete: false,
	children: '',
	isLoading: false,
	isError: false,
	confirmTitle: '',
	icon: undefined,
	onConfirm: () => {},
	deleteMsg: undefined,
	deleteMsgDescription: undefined,
};

export const useModalStore = create<ModalStore>()((set, get) => ({
	...initialValues,
	onCancel: () => {
		set({
			...initialValues,
		});
	},

	openModal: (params: OpenModalParams) => {
		const { onConfirm, onCancel, ...rest } = params;

		set({
			...rest,
			isOpen: true,
			onConfirm: async () => {
				try {
					set({ isLoading: true });
					await onConfirm();
					set({ isOpen: false, isError: false });
				} catch (error) {
					set({ isError: true });
				} finally {
					set({ isLoading: false });
				}
			},
			onCancel: () => {
				set({ ...initialValues });
				onCancel && onCancel();
			},
		});
	},
}));

export const useOpenModal = () => {
	return useModalStore((state) => state.openModal);
};
