import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const NewMenuRoutes = {
	NEW_MENU_MANAGEMENT: route(
		'new-menu-management',
		{},
		{
			MENU: route('main-menu', {}),
			BRANCH_MENU: route('branch-menu', {}),
		}
	),
};
