import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const RecordsRoutes = {
	CUSTOMERS_MANAGEMENT: route(
		'customers-management',
		{},
		{
			CUSTOMERS: route(
				'customers',
				{},
				{
					DETAILS: route(':memberId', {
						params: {
							memberId: yup(Yup.string().required()),
						},
					}),
				}
			),
			ACTIVITIES: route('activities', {}),
		}
	),
};
