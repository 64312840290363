import React from 'react';

import { useQuery } from '@app/QueryClient';

import { generatedKeys } from '../queryKeysFactory';
import {
	apiBillingAndPayments,
	apiSubscriptionDetails,
} from '@app/Services/Settings/billingAndPayment';
import { usePagination } from '@zeal/zeal-lib';
import { TApiSubscriptionDetails } from '@modules/Settings/components/PlanComponents/types';

const { settings } = generatedKeys;

export function useGetBillingAndPaymentsDetails() {
	const q = useQuery({
		queryFn: apiBillingAndPayments,
		staleTime: 1000 * 60 * 15, // 15 min,
		queryKey: settings.billingAndPayments({}) ?? [],
	});

	const pagination = usePagination({
		//@ts-ignore
		pageNum: q?.data?.meta?.current_page || 1,
		//@ts-ignore
		pagesCount: q?.data?.meta?.last_page || 1,
	});

	return { ...q, invoicesPagination: pagination };
}

export function useGetSubscriptionDetails() {
	const q = useQuery({
		queryFn: apiSubscriptionDetails,
		staleTime: 1000 * 60 * 15, // 15 min,
		queryKey: settings.SubscriptionDetails({}) ?? [],
	});

	return { ...q, data: q?.data as unknown as TApiSubscriptionDetails };
}
