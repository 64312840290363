import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const UpgradePlanPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.UPGRADE_PLAN.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: lazy(() => import('./UpgradePlan')),
				},
				{
					path: ROUTES.UPGRADE_PLAN.$.REDIRECT.relativePath,
					element: lazy(() => import('./components/PaymentRedirect')),
				},
			],
		},
	],
};
