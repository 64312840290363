import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const HomeRoutes = {
	HOME: route(
		'home',
		{},
		{
			ONBOARDING_GUIDES: route(
				'guides',
				{},
				{
					GUIDE: route(':uuid', {
						params: {
							uuid: yup(Yup.string().uuid()),
						},
					}),
				}
			),
		}
	),
};
