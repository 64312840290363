import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const newMenuManagementPathOptions: TPathOptions = {
	apiKey: 'menu',
	path: ROUTES.MENU_MANAGEMENT.path,
	label: 'menu_management',
	iconName: 'folders',
	menuOrder: 1,
	ownApp: true,
	excludedFor: [],
	subMenu: [
		{
			path: ROUTES.NEW_MENU_MANAGEMENT.MENU.path,
			label: 'main_menu',
		},
		{
			path: ROUTES.NEW_MENU_MANAGEMENT.BRANCH_MENU.path,
			label: 'branch_menus',
		},
	],
};

export default newMenuManagementPathOptions;
