import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const cardMachinesPathOptions: TPathOptions = {
	apiKey: 'zeal-merchant-card_machines',
	path: ROUTES.CARD_MACHINES.path,
	label: 'card_machines',
	iconName: 'mobileRetro',
	menuOrder: 7,
	ownApp: false,
	subMenu: [
		{
			featureApiKey:
				'zeal-merchant-card_machines:zeal-merchant-all_card_machines',
			label: 'all_card_machines',
			path: ROUTES.CARD_MACHINES.CARD_MACHINES.path,
		},
		{
			featureApiKey: 'zeal-merchant-card_machines:zeal-merchant-customise',
			label: 'customise',
			path: ROUTES.CARD_MACHINES.CUSTOMISE.path,
		},
	],
	excludedFor: [],
};

export default cardMachinesPathOptions;
