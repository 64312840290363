import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const FreemiumAnalyticsRoutes = {
	FREEMIUM_ANALYTICS: route(
		'freemium',
		{},
		{
			DASHBOARD: route(
				'dashboard',
				{},
				{
					BRANCH: route(':uuid', {
						params: {
							uuid: yup(Yup.string().uuid()),
						},
					}),
				}
			),
		}
	),
};
