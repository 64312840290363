import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const loyaltyProgrammePathOptions: TPathOptions = {
	apiKey: 'zeal-merchant-loyalty_programme',
	exact: false,
	menuOrder: 3,
	ownApp: false,
	path: ROUTES.LOYALTY_PROGRAMME.path,
	label: 'loyalty_programme',
	excludedFor: [],
	iconName: 'gift',
	subMenu: [
		{
			featureApiKey:
				'zeal-merchant-loyalty_programme:zeal-merchant-loyalty_settings',
			label: 'loyalty_setup',
			path: ROUTES.LOYALTY_PROGRAMME.SETTINGS.path,
		},
		{
			featureApiKey:
				'zeal-merchant-loyalty_programme:zeal-merchant-loyalty_reports',
			label: 'loyalty_reports',
			path: ROUTES.LOYALTY_PROGRAMME.REPORTS.path,
		},
		{
			featureApiKey:
				'zeal-merchant-loyalty_programme:zeal-merchant-loyalty_downloads',
			label: 'downloads',
			path: ROUTES.LOYALTY_PROGRAMME.DOWNLOADS.path,
		},
	],
};

export default loyaltyProgrammePathOptions;
