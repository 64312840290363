import { useAuthStore } from '@app/store/authStore';
import { ApiRequest } from '@zeal/zeal-lib';
import {
	onLoginError,
	onLoginMutationSuccess,
	onLoginSuccess,
} from './useLogin';
import { useMutation } from '@app/QueryClient';

async function apiLoginWithAdminToken({ token }: { token: string }) {
	return new ApiRequest('v4/auth/login-with-token')
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.addHeader('token', token)
		.POST('', {
			noAuth: true,
		})
		.then(({ data }: any) =>
			onLoginSuccess({
				data,
				payload: {},
				keepSignIn: true,
			})
		)
		.catch((error) =>
			onLoginError({
				error,
				keepSignIn: true,
			})
		);
}

export function useLoginWithAdminToken(opt: any) {
	const authenticate = useAuthStore((state) => state.authenticate);

	const mutation = useMutation(apiLoginWithAdminToken, {
		...opt,
		onSuccess: (data: any) => {
			onLoginMutationSuccess({
				data,
				authenticate,
				opt: { ...opt, disableToaster: true },
			});
		},
		disableToaster: true,
	});

	const { mutate } = mutation;

	return {
		...mutation,
		doLogin: mutate,
	};
}
