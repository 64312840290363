import React from 'react';
import DefaultNavBarAvatar from './DefaultNavBarAvatar';
import { useLoggedIn } from '@app/ACL/useLogin';
import BusinessNavBarAvatar from './BusinessNavBarAvatar';
import { EMerchantType } from '@app/store/authStore';

export default function NavBarAvatar() {
	const { merchantType } = useLoggedIn();

	if (
		[
			EMerchantType.Premium,
			EMerchantType.Freemium,
			EMerchantType.Cardlink,
		].includes(merchantType as EMerchantType)
	) {
		return <BusinessNavBarAvatar />;
	}

	return <DefaultNavBarAvatar />;
}
