import React from 'react';
import { Trans } from 'react-i18next';

import { CustomStepProps } from '@zeal/web-ui';

import imgPlaceholder from './assets/imgPlaceholder.svg';

export const homeSteps: (t: (key: string) => string) => CustomStepProps[] = (
	t
) => [
	{
		selector: '#home-sidebar',
		title: t('home_sidebar_title'),
		description: (
			<Trans
				i18nKey={t('home_sidebar_description')}
				components={{
					br: <br />,
				}}
			/>
		),
		arrowPosition: 'left',
		tourPlace: 'right',
	},
	{
		selector: '#home-profile',
		title: t('home_profile_title'),
		description: t('home_profile_description'),
		img: imgPlaceholder,
		arrowPosition: 'top-right',
		tourPlace: 'bottom-left',
	},
	{
		selector: '#home-dashboard-cards',
		title: t('home_dashboard_cards_title'),
		description: t('home_dashboard_cards_description'),
		arrowPosition: 'top-left',
	},
	{
		selector: '#home-dashboard-setup',
		title: t('home_dashboard_setup_title'),

		description: (
			<Trans
				i18nKey={t('home_dashboard_setup_description')}
				components={{
					br: <br />,
				}}
			/>
		),
		arrowPosition: 'bottom',
		tourPlace: 'top',
	},
	{
		selector: '#home-announcements',
		title: t('home_announcements_title'),
		description: t('home_announcements_description'),
		arrowPosition: 'bottom',
	},
	{
		selector: '#home-guides',
		title: t('home_guides_title'),
		description: t('home_guides_description'),
		arrowPosition: 'bottom',
	},
	{
		selector: '#home-help-center',
		title: t('home_help_center_title'),
		description: t('home_help_center_description'),
		tourPlace: 'top-icon',
		arrowPosition: 'none',
	},
];
