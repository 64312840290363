import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions, RouteFlags } from '../App/types/types';

const ordersPathOptions: TPathOptions = {
	apiKey: 'menu',
	path: ROUTES.ORDERS.path,
	label: 'orders',
	iconName: 'list',
	menuOrder: 2,
	ownApp: true,
	subMenu: [
		{
			path: ROUTES.ORDERS.LIST.path,
			label: 'orders_list',
			featureApiKey: 'menu',
		},
		// Calendar is hidden to release to staging without it
		// {
		// 	path: ROUTES.ORDERS.CALENDAR.path,
		// 	label: 'orders_calendar',
		// 	isExcluded: ({ businessProperties }) => !businessProperties?.booking,
		// 	featureApiKey: 'menu',
		// },
	],
};

export default ordersPathOptions;
