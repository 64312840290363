import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const NewMenuPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.NEW_MENU_MANAGEMENT.path,
			aclProps: {
				aclGrants: ['view:menu'],
			},
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.NEW_MENU_MANAGEMENT.MENU.path} />,
				},
				{
					path: ROUTES.NEW_MENU_MANAGEMENT.$.MENU.relativePath,
					element: lazy(() => import('./components/MainMenu/MainMenu')),
				},
				{
					path: ROUTES.NEW_MENU_MANAGEMENT.$.BRANCH_MENU.relativePath,
					element: lazy(() => import('./components/BranchMenu/BranchMenu')),
				},
			],
		},
	],
};
