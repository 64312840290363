import { CustomStepProps } from '@zeal/web-ui';

export const branchesSteps: (
	t: (key: string) => string
) => CustomStepProps[] = (t) => [
	{
		selector: '#branch-add-button',
		title: t('add_branch_title'),
		description: t('add_branch_description'),
		arrowPosition: 'right',
		tourPlace: 'left',
	},
	{
		selector: '#branch-drawer',
		title: t('add_branch_drawer_title'),
		description: t('add_branch_drawer_description'),
		arrowPosition: 'right',
		tourPlace: 'bottom-left',
		tourOverrideStyles: '!left-[-10px] !top-[100px] ',
	},
	{
		selector: '.branch-card',
		title: t('branch_card_title'),
		description: t('branch_card_description'),
		arrowPosition: 'right',
	},
	{
		// selector: '.branch-actions',
		selector: '[data-tour="highlighted-element"]',
		highlightedSelectors: ['.branch-actions'],
		mutationObservables: ['.branch-actions'],
		title: t('branch_actions_button_title'),
		description: t('branch_actions_button_description'),
		arrowPosition: 'left',
	},
];
