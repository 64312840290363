import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const branchesPathOptions: TPathOptions = {
	apiKey: 'branches',
	excludedFor: [],
	path: ROUTES.BRANCHES.path,
	label: 'branches',
	iconName: 'store',
	menuOrder: 8,
	ownApp: false,
};

export default branchesPathOptions;
