import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, InlineLoading, Label, Shimmer } from '@zeal/web-ui';

import UnAuthOwnApp from './components/UnAuthOwnApp';
import UnAuthorized from './components/UnAuthorized';
import { useOwnAppAccess, useOwnAppProvider } from './useOwnApp';
import { Content, MainGrid } from './components/StyledComponents';
import { AppContainerLoading } from './components/AppContainerLoading';

import AppMenu from '../AppMenu';
import { useLoggedIn, useLoginData } from '../ACL/useLogin';
import { useAccountInfo } from '../BusinessAccount/useBusinessInfo';
import useGrants from './../ACL/useGrants';
import NavBar from './NavBar';
import { useAppState } from '../store/appStore';
import { usePageStore } from '@app/store/pageStore';
import { getAppRoutes } from '@app/utils/appRoutes';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useFetchTranslation } from '@app/hooks';
import { useAuthStore, useLoginVault } from '@app/store/authStore';
import { ROUTES } from '@app/AppRoutes/routes';
import { usePusherSubscription } from '../Pusher/usePusherSubscription';
import CancellationBanner from './components/CancellationBanner';
import { useGetSubscriptionDetails } from '@app/data/Settings/useGetBillingAndPaymentsDetails';

const mainGridVariants: { [key: string]: any } = {
	open: { '--main-navbar-width': '18.5rem' },
	minimized: { '--main-navbar-width': '5.5rem' },
};

const { ownAppMenus, appMainMenus } = getAppRoutes();

const appRoutes = [...ownAppMenus, ...appMainMenus];

function AppContainer() {
	const { i18n, t } = useTranslation('sidedrawer');

	const { isTranslationsLoading } = useFetchTranslation('sidedrawer');
	const { isTranslationsLoading: isNavbarTranslationLoading } =
		useFetchTranslation('navbar');

	const authenticate = useAuthStore((state) => state.authenticate);
	const loggedInData = useLoginVault();
	const { data } = useLoginData();
	const { data: subscriptionDetails } = useGetSubscriptionDetails();
	const navigate = useNavigate();

	const [isCancellationBannerVisible, setIsCancellationBannerVisible] =
		useState(false);

	useEffect(() => {
		if (subscriptionDetails) {
			const showBanner =
				subscriptionDetails?.status === 'scheduled_cancellation' &&
				new Date().getTime() <
					new Date(subscriptionDetails?.next_billing_date).getTime();

			setIsCancellationBannerVisible(showBanner);
		}
	}, [subscriptionDetails]);

	useEffect(() => {
		if (data) {
			if (data?.type != loggedInData?.merchantType) {
				// TODO: Update this to avoid NotFound page flickering before redirection
				navigate(ROUTES.ROOT.path);
			}

			const permissions = {
				permissions: data?.business_admin?.roles?.[0]?.permissions,
				cuuid: data.business_admin?.uuid,
			};
			authenticate(
				{
					...loggedInData,
					isSuperAdmin: data?.business_admin?.is_super_admin,
					uuid: data?.business_admin?.uuid,
					businessName: data?.business_admin?.business?.name,
					email: data?.business_admin?.email,
					type: data?.business_admin?.business?.type,
					isNIMerchant: data?.business_admin?.business?.type === 'ni',
					name: data?.business_admin?.name,
					isUkMerchant: data?.is_uk,
					merchantType: data?.type,
				},
				permissions
			);
		}
	}, [data]);

	const { pathname } = useLocation();
	const { pageConfig, appMenu: appMenuState } = usePageStore();

	const pageParams = useMemo(() => {
		return (appRoutes || []).find(
			(route) =>
				pathname === route.path ||
				!!route?.subMenu?.find((sub) => sub.path === pathname)
		);
	}, [pathname]);

	const isOwnApp = pageParams?.ownApp;

	useGrants();
	useAccountInfo();
	useOwnAppProvider(isOwnApp ?? false);

	const { isTranslationsLoading: isPageTranslationLoading } =
		useFetchTranslation(pathname);

	const { isLoggedIn } = useLoggedIn();
	const { isRouteAllowed } = useOwnAppAccess();
	const { isSwitchedOnLoad } = useAppState();
	const isMenuMini = useMemo(
		() => appMenuState === 'minimized',
		[appMenuState]
	);

	const pageLabel = useMemo(() => {
		return (
			pageParams?.subMenu?.find((sub) => sub.path === pathname)?.label ||
			pageParams?.label
		);
	}, [pageParams, pathname]);

	const title = pageLabel ? t(pageLabel) : '';

	useEffect(() => {
		document.dir = i18n.dir();

		document.documentElement.setAttribute(
			'lang',
			i18n.language || i18n.resolvedLanguage || 'en-GB'
		);
	}, [i18n, i18n.language]);

	const pageTitleComp = useMemo(() => {
		return pageConfig?.title || title;
	}, [pageConfig?.title, title]);

	const pageTitle = useMemo(
		() =>
			isPageTranslationLoading ? (
				<Shimmer width="6xl" height="10" rounded="full" />
			) : pageTitleComp ? (
				<Label.Mid500 id="pageTitle">{pageTitleComp}</Label.Mid500>
			) : null,
		[pageTitleComp, isPageTranslationLoading]
	);

	const content = isRouteAllowed(isOwnApp) ? <Outlet /> : <UnAuthOwnApp />;

	usePusherSubscription();

	if (!isLoggedIn) {
		return <UnAuthorized />;
	}

	if (
		!isSwitchedOnLoad ||
		isTranslationsLoading ||
		isNavbarTranslationLoading
	) {
		return <AppContainerLoading />;
	}

	return (
		<MainGrid
			animate={appMenuState}
			variants={mainGridVariants}
			initial={appMenuState}
			transition={{
				type: 'easeIn',
				duration: 0.2,
			}}
		>
			<NavBar
				pageTitle={pageTitle}
				tabs={pageConfig.middleContent}
				setIsCancellationBannerVisible={setIsCancellationBannerVisible}
			/>
			{isCancellationBannerVisible && (
				<CancellationBanner
					setIsCancellationBannerVisible={setIsCancellationBannerVisible}
				/>
			)}
			<AppMenu gridArea="menu" isCollapsed={isMenuMini} />

			<React.Suspense fallback={<LazyLoadingSuspenseFallback />}>
				<Content id="app-container-content">
					{isPageTranslationLoading ? <AppContainerLoading /> : content}
				</Content>
			</React.Suspense>
		</MainGrid>
	);
}

const LazyLoadingSuspenseFallback = () => {
	return (
		//TODO: REPLACE with ui/ux team
		<Flex justify="center" align="center" className="h-screen">
			<InlineLoading />
		</Flex>
	);
};

export default memo(AppContainer);
