import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const homePathOptions: TPathOptions = {
	apiKey: 'zeal-merchant-home',
	path: ROUTES.HOME.path,
	label: 'home',
	iconName: 'house',
	menuOrder: 1,
	excludedFor: [],
	ownApp: false,
};

export default homePathOptions;
