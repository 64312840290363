import { route } from 'react-router-typesafe-routes/dom';

export const TransactionsRoutes = {
	TRANSACTIONS: route(
		'transactions',
		{},
		{
			TRANSACTIONS: route('list', {}),
			DOWNLOADS: route('downloads', {}),
		}
	),
};
