import { useDebugValue } from 'react';

import { generatedKeys } from '@app/data/queryKeysFactory';
import { useQuery } from '@app/QueryClient';
import { apiGuide, apiGuides } from '../../Services/Home';
import { useTranslation } from 'react-i18next';

const { freeOnboarding } = generatedKeys;

export function useGuides() {
	const { i18n } = useTranslation();
	const lang = i18n.language || i18n.resolvedLanguage;

	const q = useQuery({
		queryFn: () => apiGuides(lang, 1),
		staleTime: Infinity,
		queryKey: freeOnboarding?.guides?.({ lang }),
	});

	useDebugValue(q);

	return { ...q, data: q?.data?.data };
}

export function useGuide(id?: string) {
	const { i18n } = useTranslation();
	const lang = i18n.language || i18n.resolvedLanguage;

	const q = useQuery({
		queryFn: () => apiGuide(lang, id || ''),
		staleTime: Infinity,
		enabled: !!id,
		queryKey: freeOnboarding?.guide?.({ lang, id }),
	});

	useDebugValue(q);

	return { ...q, data: q?.data?.data };
}
