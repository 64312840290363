import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const LoyaltyProgrammePathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.LOYALTY_PROGRAMME.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.LOYALTY_PROGRAMME.SETTINGS.path} />,
				},
				{
					path: ROUTES.LOYALTY_PROGRAMME.$.SETTINGS.relativePath,
					element: lazy(() => import('./FreemiumLoyalty')),
				},
				{
					path: ROUTES.LOYALTY_PROGRAMME.$.REPORTS.relativePath,
					element: lazy(() => import('./LoyaltyReports')),
				},
				{
					path: ROUTES.LOYALTY_PROGRAMME.$.DOWNLOADS.relativePath,
					element: lazy(() => import('./Downloads')),
				},
			],
		},
		{
			path: ROUTES.LOYALTY_SETUP.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: lazy(
						() => import('./components/LoyaltySetupOptions/LoyaltyLanding')
					),
				},
				{
					path: ROUTES.LOYALTY_SETUP.$.LOYALTY_EXISTENCE_QUESTION.relativePath,
					element: lazy(
						() =>
							import(
								'./components/LoyaltySetupOptions/components/LoyaltyExistenceQuestions'
							)
					),
				},
				{
					path: ROUTES.LOYALTY_SETUP.$.LOYALTY_LINK.relativePath,
					element: lazy(
						() =>
							import('./components/LoyaltySetupOptions/components/LoyaltyLink')
					),
				},

				{
					path: ROUTES.LOYALTY_SETUP.$.CLASSIC_ZEAL.relativePath,
					element: lazy(
						() =>
							import(
								'./components/LoyaltySetupOptions/components/LoyaltyModelPreview'
							)
					),
				},
				{
					path: ROUTES.LOYALTY_SETUP.$.MICRO_LOYALTY.relativePath,
					element: lazy(
						() => import('./components/LoyaltySetupOptions/LoyaltyLanding')
					),
				},
			],
		},
	],
};
