import { route } from 'react-router-typesafe-routes/dom';

export const LoyaltyProgrammeRoutes = {
	LOYALTY_PROGRAMME: route(
		'loyalty',
		{},
		{
			SETTINGS: route('settings', {}),
			REPORTS: route('reports', {}),
			DOWNLOADS: route('downloads', {}),
		}
	),
	LOYALTY_SETUP: route(
		'loyalty-setup',
		{},
		{
			LOYALTY_EXISTENCE_QUESTION: route('loyalty-existence', {}),
			LOYALTY_LINK: route('loyalty-link', {}),
			CLASSIC_ZEAL: route('classic-zeal', {}),
			MICRO_LOYALTY: route('micro-zeal', {}),
			CONFIRMATION: route('confirmation', {}),
		}
	),
};
