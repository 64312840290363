import { route } from 'react-router-typesafe-routes/dom';
import { yup } from 'react-router-typesafe-routes/yup';
import * as Yup from 'yup';

export const UpgradePlanRoutes = {
	UPGRADE_PLAN: route(
		'upgrade-plan',
		{},
		{
			REDIRECT: route('upgrade-redirect', {}),
		}
	),
};
