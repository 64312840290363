import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const transactionsPathOptions: TPathOptions = {
	apiKey: 'zeal-merchant-transactions',
	exact: false,
	menuOrder: 5,
	ownApp: false,
	path: ROUTES.TRANSACTIONS.path,
	label: 'transactions',
	excludedFor: [],
	iconName: 'fileChartColumn',
	subMenu: [
		{
			featureApiKey:
				'zeal-merchant-transactions:zeal-merchant-transactions_overview',
			label: 'transactions_overview',
			path: ROUTES.TRANSACTIONS.TRANSACTIONS.path,
		},
		{
			featureApiKey:
				'zeal-merchant-transactions:zeal-merchant-transactions_downloads',
			label: 'downloads',
			path: ROUTES.TRANSACTIONS.DOWNLOADS.path,
		},
	],
};

export default transactionsPathOptions;
