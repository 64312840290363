import React from 'react';

import { ZealTour } from '@zeal/web-ui';

import { useUpdateOnboardingKeys } from '@app/data/Tour/useUpdateOnboardingKeys';
import { ApiStepsMapping } from './components/stepsMapping';
import { useTour } from './hooks/useTour';
import useFilterTourSteps from './hooks/useFilterTourSteps';

interface TourProps {
	beforeCloseTour?: () => void;
	t: any;
	elementId?: string;
	stepName?: string;
	onClickNextHandler?: (nextStepNumber: number) => void;
	onClickPrevHandler?: (nextStepNumber: number) => void;
	zealTourRef?: React.RefObject<{
		goToStepNumber: (step: number) => void;
	}>;
	setCurrentExternalStep?: React.Dispatch<React.SetStateAction<number>>;
	key?: string;
	update?: string;
	updateDelay?: number;
}

const Tour = ({
	beforeCloseTour,
	t,
	elementId,
	stepName,
	onClickNextHandler,
	zealTourRef,
	setCurrentExternalStep,
	onClickPrevHandler,
	key,
	update,
	updateDelay,
}: TourProps) => {
	const { isElementPresent } = useTour({
		elementId: elementId || '',
		tourName: stepName || '',
	});

	const filteredSteps = useFilterTourSteps({ stepName, t });

	const { doUpdateOnboardingKeys } = useUpdateOnboardingKeys();

	const handleFinishTour = () => {
		const key = (stepName && ApiStepsMapping[stepName]) || '';
		doUpdateOnboardingKeys({ key });
		if (beforeCloseTour) {
			beforeCloseTour();
		}
	};

	return (
		<ZealTour
			key={key}
			data-testid="tour"
			isOpen={isElementPresent}
			steps={filteredSteps}
			type="withBar"
			showProgress={true}
			showBackBtn={true}
			disableInteractions={true}
			beforeClose={handleFinishTour}
			onClickNextHandler={onClickNextHandler}
			onClickPrevHandler={onClickPrevHandler}
			zealTourRef={zealTourRef}
			setCurrentExternalStep={setCurrentExternalStep}
			update={update}
			updateDelay={updateDelay}
		/>
	);
};

export default Tour;
