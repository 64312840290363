import { ApiRequest } from '@zeal/zeal-lib';
import { ENDPOINTS } from '../endpoints';
import {
	TApiInvoice,
	TApiSubscriptionDetails,
} from '@modules/Settings/components/PlanComponents/types';

export async function apiBillingAndPayments({ queryKey: [] }: any) {
	return new ApiRequest(ENDPOINTS.settings.billingAndPayments)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET<ApiResponse<TApiInvoice[]>>();
}

export async function apiSubscriptionDetails({ queryKey: [] }: any) {
	return new ApiRequest(ENDPOINTS.settings.invoicesDetails)
		.addHeader('Accept', 'application/json')
		.addHeader('Content-Type', 'application/json')
		.GET<ApiResponse<TApiSubscriptionDetails>>();
}
