import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const freemiumTeamsPathOptions: TPathOptions = {
	apiKey: 'zeal-merchant-teams',
	exact: false,
	menuOrder: 9,
	ownApp: false,
	path: ROUTES.FREEMIUM_TEAMS.path,
	label: 'teams',
	iconName: 'userGroup',
	excludedFor: [],
	subMenu: [
		{
			featureApiKey: 'zeal-merchant-teams:zeal-merchant-teams_administrators',
			label: 'administrators',
			path: ROUTES.FREEMIUM_TEAMS.ADMINISTRATORS.path,
		},
		{
			featureApiKey: 'zeal-merchant-teams:zeal-merchant-teams_members',
			label: 'team_members',
			path: ROUTES.FREEMIUM_TEAMS.TEAMS.path,
		},
	],
};

export default freemiumTeamsPathOptions;
