import React from 'react';
import { Flex, Icon } from '@zeal/web-ui';
import { useGetSubscriptionDetails } from '@app/data/Settings/useGetBillingAndPaymentsDetails';

export default function CancellationInfoIcon({
	setIsCancellationBannerVisible,
}: {
	setIsCancellationBannerVisible: (open: boolean) => void;
}) {
	const { data: subscriptionDetails } = useGetSubscriptionDetails();
	const showInfoIcon =
		subscriptionDetails?.status === 'scheduled_cancellation' &&
		new Date().getTime() <
			new Date(subscriptionDetails?.next_billing_date).getTime();

	if (!showInfoIcon) {
		return null;
	}

	return (
		<Flex
			data-testid="cancellation-info-icon"
			align="center"
			justify="center"
			className="rounded-full bg-surface-critical-low w-10 h-10 p-2 cursor-pointer"
			onClick={() => setIsCancellationBannerVisible(true)}
		>
			<Icon
				iconName="infoCircle"
				variant="regular"
				colorVariant="critical"
				size="lg"
			/>
		</Flex>
	);
}
