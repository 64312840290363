import React from 'react';
import { StyledDowngradeBanner } from './StyledComponents';
import { useTranslation } from 'react-i18next';
import { useDate } from '@zeal/zeal-lib';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/AppRoutes/routes';
import { useGetSubscriptionDetails } from '@app/data/Settings/useGetBillingAndPaymentsDetails';

export default function CancellationBanner({
	setIsCancellationBannerVisible,
}: {
	setIsCancellationBannerVisible: (open: boolean) => void;
}) {
	const { data: subscriptionDetails } = useGetSubscriptionDetails();
	const navigate = useNavigate();
	const { t } = useTranslation('common');
	const { formatLocaleDate } = useDate();

	return (
		<StyledDowngradeBanner
			className="cursor-default"
			id="downgrade-banner"
			variant="error"
			intense
			onDismiss={() => setIsCancellationBannerVisible(false)}
			message={t('cancellation_banner_title')}
			description={t('cancellation_banner_description', {
				expiration_date: formatLocaleDate(
					subscriptionDetails?.next_billing_date
				),
			})}
			iconProps={{
				iconName: 'circleXmark',
				size: 'lg',
			}}
			action={{
				label: t('upgrade'),
				onClick: () => navigate(ROUTES.UPGRADE_PLAN.relativePath),
			}}
		/>
	);
}
