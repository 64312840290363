import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const RecordsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.CUSTOMERS_MANAGEMENT.path,
			aclProps: {
				aclGrants: ['view:customers'],
			},
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.CUSTOMERS_MANAGEMENT.CUSTOMERS.path} />,
				},
				{
					path: ROUTES.CUSTOMERS_MANAGEMENT.$.CUSTOMERS.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./Consumers')),
						},
						{
							path: ROUTES.CUSTOMERS_MANAGEMENT.$.CUSTOMERS.$.DETAILS
								.relativePath,
							element: lazy(() => import('./Members')),
						},
					],
				},
				{
					path: ROUTES.CUSTOMERS_MANAGEMENT.$.ACTIVITIES.relativePath,
					element: lazy(() => import('./Activities')),
				},
			],
		},
	],
};
