import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const TransactionsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.TRANSACTIONS.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.TRANSACTIONS.TRANSACTIONS.path} />,
				},
				{
					path: ROUTES.TRANSACTIONS.$.TRANSACTIONS.relativePath,
					element: lazy(() => import('./Transactions')),
				},
				{
					path: ROUTES.TRANSACTIONS.$.DOWNLOADS.relativePath,
					element: lazy(() => import('./Downloads')),
				},
			],
		},
	],
};
