import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Outlet } from 'react-router-dom';

export const HomePathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.HOME.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: lazy(() => import('./Home')),
				},
				{
					path: ROUTES.HOME.$.ONBOARDING_GUIDES.relativePath,
					element: <Outlet />,
					children: [
						{
							index: true,
							element: lazy(() => import('./OnboardingGuides')),
						},
						{
							path: ROUTES.HOME.ONBOARDING_GUIDES.$.GUIDE.relativePath,
							element: lazy(() => import('./OnboardingGuideDetails')),
						},
					],
				},
			],
		},
	],
};
