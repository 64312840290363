import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';
import SettingsFrame from './SettingsFrame';

export const settingsPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.SETTINGS.path,
			element: (
				<SettingsFrame>
					<Outlet />
				</SettingsFrame>
			),
			aclProps: {
				aclGrants: ['superAdmin'],
			},
			children: [
				{
					index: true,
					element: <Navigate to={ROUTES.SETTINGS.PROFILE.path} />,
				},
				{
					path: ROUTES.SETTINGS.ACCOUNT.path,
					element: lazy(() => import('./Views/Account')),
					featureApiKey: 'settings:settings_dashboard:settings_account',
				},
				{
					path: ROUTES.SETTINGS.PLAN.path,
					element: lazy(() => import('./Views/Plan')),
					featureApiKey:
						'settings:settings_dashboard:zeal-merchant-settings_plan',
				},
				{
					path: ROUTES.SETTINGS.PROFILE.path,
					element: lazy(() => import('./Views/Profile')),
					featureApiKey: 'settings:settings_app_settings:settings_profile',
				},
				{
					path: ROUTES.SETTINGS.TAP_TO_PAY.path,
					element: lazy(() => import('./Views/TapToPay')),
					featureApiKey: 'settings:settings_app_settings:settings_tap_to_pay',
				},
				{
					path: ROUTES.SETTINGS.POS.path,
					element: lazy(() => import('./Views/POS')),
				},
				{
					path: ROUTES.SETTINGS.SOCIAL_MEDIA.path,
					element: lazy(() => import('./Views/SocialMedia')),
					featureApiKey: 'settings:settings_app_settings:settings_social_media',
				},
				{
					path: ROUTES.SETTINGS.SUBSCRIPTION.path,
					element: lazy(() => import('./Views/Subscription')),
				},
				{
					path: ROUTES.SETTINGS.CATEGORIES.path,
					element: lazy(() => import('./Views/Categories')),
					featureApiKey: 'settings:settings_app_settings:settings_categories',
				},
				{
					path: ROUTES.SETTINGS.STRIPE.path,
					element: lazy(() => import('./Views/StripeConnect/StripeConnect')),
					featureApiKey: 'settings:settings_dashboard:settings_stripe',
				},
				{
					path: ROUTES.SETTINGS.BILLING.path,
					element: lazy(() => import('./Views/Billing/Billing')),
					featureApiKey:
						'settings:settings_dashboard:zeal-merchant-settings_billing',
				},
				{
					path: ROUTES.SETTINGS.BOOKING.path,
					element: lazy(() => import('./components/BookingSettings')),
					featureApiKey: 'settings:settings_booking:settings_booking_settings',
				},
				{
					path: ROUTES.SETTINGS.PAYMENT_METHOD.path,
					element: lazy(() => import('./components/PaymentMethods')),
					featureApiKey:
						'settings:settings_app_settings:settings_payment_method',
				},
				{
					path: ROUTES.SETTINGS.REFUND.path,
					element: lazy(() => import('./Views/RefundSettings')),
					featureApiKey: 'settings:settings_booking:settings_refund',
				},
				{
					path: ROUTES.SETTINGS.POS_LANGUAGE.path,
					element: lazy(() => import('./Views/POSLanguage')),
					featureApiKey: 'settings:settings_pos_terminal:settings_pos_language',
				},
				{
					path: ROUTES.SETTINGS.WEBHOOK_SETTINGS.path,
					element: lazy(() => import('./Views/CardLink/WebhookSettings')),
					featureApiKey: 'settings:cardlink_settings:webhook_settings',
				},

				{
					path: ROUTES.SETTINGS.TERMS_AND_CONDITIONS.path,
					element: lazy(() => import('./Views/CardLink/TermsAndConditions')),
					featureApiKey: 'settings:cardlink_settings:terms_conditions_settings',
				},
			],
		},
	],
};
