import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Flex, Grid } from '@zeal/web-ui';

import { usePageConfig } from '@app/hooks/usePageConfig';

import SettingsMenuCard from './data/MenuMap';
import Tour from '@app/Tour/Tour';
import { useListOnboardingSettings } from '@app/data/Tour/useListOnboardingSettings';
import { ApiStepsMapping } from '@app/Tour/components/stepsMapping';
import { useTourStore } from '@app/store/tourStore';
import { useLocation } from 'react-router-dom';

export default function SettingsFrame(props: React.PropsWithChildren<{}>) {
	const { t } = useTranslation('settings');

	const [showTour, setShowTour] = React.useState(true);

	usePageConfig({ title: t('settings') });

	const { data: onboardingSettings, refetch: settingsRefetch } =
		useListOnboardingSettings();
	const { setActiveTour } = useTourStore();

	const location = useLocation();

	React.useEffect(() => {
		if (location.pathname === '/settings/profile') {
			setActiveTour('settings');
		}
	}, [location, setActiveTour]);

	React.useEffect(() => {
		settingsRefetch();
	}, [settingsRefetch]);

	React.useEffect(() => {
		if (onboardingSettings) {
			const currentPageKey = ApiStepsMapping['settings'];
			const isCompleted =
				Array.isArray(onboardingSettings) &&
				onboardingSettings.some(
					(setting) => setting.key === currentPageKey && setting.onboarded
				);
			setShowTour(!isCompleted);
		}
	}, [onboardingSettings]);

	return (
		<Card m="md" p="none">
			<Grid
				cols={6}
				className="[grid-template-columns: minmax(250px, 1fr) repeat(5, 1fr)]"
			>
				<Flex isColumn gap="md">
					<div className="h-full min-h-screen col-span-1 border-r-2 border-gray-100">
						{SettingsMenuCard}
					</div>
				</Flex>
				<div className="h-full col-span-5">{props.children}</div>
				{showTour && (
					<Tour t={t} elementId="settings-profile" stepName="settings" />
				)}
			</Grid>
		</Card>
	);
}
