import { useEffect } from 'react';
import Pusher from 'pusher-js';
import { useBusinessInfo } from '@app/BusinessAccount/useBusinessInfo';
import { EVENT_HANDLERS } from './eventHandlers';

const PUSHER_CONFIG = {
	appKey: process.env.REACT_APP_PUSHER_APP_KEY!,
	cluster: process.env.REACT_APP_PUSHER_CLUSTER!,
};

export const channelName = (businessUuid: string) =>
	`zeal.dashboard.${businessUuid}`;

export const usePusherSubscription = () => {
	const { data: businessInfo } = useBusinessInfo();
	const businessUuid = businessInfo?.business?.uuid;

	useEffect(() => {
		// If no business uuid, don't subscribe
		if (!businessUuid) return;

		// Initialize Pusher
		const pusher = new Pusher(PUSHER_CONFIG.appKey, {
			cluster: PUSHER_CONFIG.cluster,
		});

		// Subscribe to the channel
		const channel = pusher.subscribe(channelName(businessUuid));

		// Listen for subscription events
		EVENT_HANDLERS.forEach((event) => {
			channel.bind(event.name, event.callback);
		});

		// Cleanup
		return () => {
			channel.unbind_all();
			channel.unsubscribe();
			pusher.disconnect();
		};
	}, [businessUuid]);
};
