import { queryClient } from '@app/QueryClient';

export type SubscriptionEvent = {
	name: string;
	callback: (data: any) => void;
};

export const EVENT_HANDLERS: SubscriptionEvent[] = [
	{
		name: 'subscription.created',
		callback: (data: any) => {
			queryClient.invalidateQueries({});
		},
	},
];
