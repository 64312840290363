import React, { lazy } from 'react';
import pathOpt from './pathOptions';
import { IModuleOptions } from '@app/types/types';
import { ROUTES } from '@app/AppRoutes/routes';
import { Navigate, Outlet } from 'react-router-dom';

export const CardMachinesPathEntry: IModuleOptions = {
	...pathOpt,
	routes: [
		{
			path: ROUTES.CARD_MACHINES.path,
			element: <Outlet />,
			children: [
				{
					index: true,
					element: (
						<Navigate to={ROUTES.CARD_MACHINES.CARD_MACHINES.path} replace />
					),
				},
				{
					path: ROUTES.CARD_MACHINES.$.CARD_MACHINES.relativePath,
					element: lazy(() => import('./components/branchTerminals')),
					aclProps: {
						aclGrants: [],
					},
				},
				{
					path: ROUTES.CARD_MACHINES.$.CUSTOMISE.relativePath,
					element: lazy(() => import('./terminalCustomization/index')),
					aclProps: {
						aclGrants: [],
					},
				},
			],
		},
	],
};
