declare global {
	interface Window {
		FreshworksWidget: any;
		fwSettings: any;
	}
}

const HELP_WIDGET_ID = 150000002166;
const PREMIUM_WIDGET_ID = 150000004943;

export const WIDGETS = {
	HELP_WIDGET: HELP_WIDGET_ID,
	PREMIUM_WIDGET: PREMIUM_WIDGET_ID,
};

const WIDGETS_OPTIONS: Record<
	number,
	{
		widget_id: number;
		locale?: string;
		group_id?: number;
	}
> = {
	[HELP_WIDGET_ID]: {
		widget_id: HELP_WIDGET_ID,
		locale: 'en',
		group_id: 150000187055,
	},
	[PREMIUM_WIDGET_ID]: {
		widget_id: PREMIUM_WIDGET_ID,
	},
};

const loadFreshworksWidgetScript = () => {
	const script = document.createElement('script');
	script.src = `https://widget.freshworks.com/widgets/${HELP_WIDGET_ID}.js`;
	script.async = true;
	script.defer = true;
	script.onload = function () {
		console.log('FreshworksWidget loaded');

		setTimeout(() => {
			window.FreshworksWidget('open');
			window.FreshworksWidget('hide', 'launcher');
		}, 500);
	};
	document.head.appendChild(script);
};

/**
 * @param widgetId - The widget ID to initiate. Defaults to HELP_WIDGET_ID.
 */
export const initiateFreshworksWidget = (widgetId: number = HELP_WIDGET_ID) => {
	if (window.FreshworksWidget) {
		window.FreshworksWidget('destroy');
	}

	window.fwSettings = { ...WIDGETS_OPTIONS[widgetId] };

	// @ts-ignore
	!(function () {
		if ('function' != typeof window.FreshworksWidget) {
			var n = function () {
				// @ts-ignore
				n.q.push(arguments);
			};
			// @ts-ignore
			(n.q = []), (window.FreshworksWidget = n);
		}
	})();

	loadFreshworksWidgetScript();
};
