import { useAnnouncements, useGuides } from '@app/data/Home';
import { CustomStepProps } from '@zeal/web-ui';
import { useCallback, useMemo } from 'react';
import { stepsMapping } from '../components/stepsMapping';

type Args = {
	t: any;
	stepName?: string;
};
export default function useFilterTourSteps({ stepName = 'home', t }: Args) {
	const { data: guidesData, isSuccess: isGuideDataSuccess } = useGuides();
	const { data: announcementsData, isSuccess: isAnnouncementsSuccess } =
		useAnnouncements();

	const filterNonExist = useCallback(
		(steps: CustomStepProps[]) => {
			return steps.filter((s) => {
				if (
					isGuideDataSuccess &&
					!guidesData.length &&
					s.selector === '#home-guides'
				) {
					return false;
				}

				if (
					isAnnouncementsSuccess &&
					!announcementsData.length &&
					s.selector === '#home-announcements'
				) {
					return false;
				}

				return true;
			});
		},
		[isGuideDataSuccess, guidesData, isAnnouncementsSuccess, announcementsData]
	);

	const filteredSteps = useMemo(() => {
		const stepsFunction = stepName
			? stepsMapping[stepName]
			: stepsMapping['home'];
		if (stepsFunction) {
			const steps = stepsFunction(t);
			return stepName === 'home' ? filterNonExist(steps) : steps;
		}
	}, [stepName, stepsMapping, t, filterNonExist]);

	return filteredSteps ?? [];
}
