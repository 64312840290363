import { ROUTES } from '@app/AppRoutes/routes';
import { TPathOptions } from '../App/types/types';

const customersPathOptions: TPathOptions = {
	apiKey: 'zeal-merchant-customers',
	exact: false,
	menuOrder: 4,
	ownApp: false,
	path: ROUTES.CUSTOMERS.path,
	label: 'customers',
	excludedFor: [],
	iconName: 'user',
};

export default customersPathOptions;
