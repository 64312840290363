// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
	getMessaging,
	getToken,
	onMessage,
	isSupported,
	MessagePayload,
	deleteToken,
} from 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyA9wCLaQILbGJ85AGK6XDHUjFZCchyzff8',
	authDomain: 'zeal-portal-375709.firebaseapp.com',
	projectId: 'zeal-portal-375709',
	storageBucket: 'zeal-portal-375709.appspot.com',
	messagingSenderId: '351375003601',
	appId: '1:351375003601:web:540b1d7a3bd98814495af0',
	measurementId: 'G-1GHMNPXCQ8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = (async () => {
	try {
		const isSupportedBrowser = await isSupported();
		if (isSupportedBrowser) {
			return getMessaging(app);
		}

		return null;
	} catch (err) {
		console.log(err);
		return null;
	}
})();

export async function getFireBaseToken() {
	const messagingResolve = await messaging;

	if (!messagingResolve) {
		return null;
	}

	return getToken(messagingResolve, {
		vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
	})
		.then((currentToken) => {
			if (currentToken) {
				// Track the token -> client mapping, by sending to backend server
				// show on the UI that permission is secured
				return currentToken;
			} else {
				console.log(
					'No registration token available. Request permission to generate one.'
				);
				// shows on the UI that permission is required

				return null;
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			// catch error while creating client token

			return null;
		});
}

export const onMessageListener: () => Promise<MessagePayload | null> =
	async () => {
		const messagingResolve = await messaging;

		if (!messagingResolve) {
			return null;
		}

		return new Promise((resolve) => {
			onMessage(messagingResolve, (payload) => {
				resolve(payload);
			});
		});
	};

export const deleteFirebaseToken = async () => {
	const messagingResolve = await messaging;

	if (!messagingResolve) {
		return null;
	}

	return deleteToken(messagingResolve);
};
